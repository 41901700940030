<template>
  <div>
    <el-button style="margin-left: 3%" v-show="!isSuperAdmin" type="primary" plain @click="dialogFormVisible = true">
      创建培训批次
    </el-button>
    <el-dialog title="新增批次" :visible.sync="dialogFormVisible" width="40%">
      <el-form :model="batch" :rules="batchRules" ref="batch">
        <el-form-item label="批次名称:" label-width="100px" prop="batchName">
          <el-input :span="11" v-model="batch.name" style="width: 100%" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设置发证日期:" style="width: 100%" label-width="100px" prop="batchDate">
          <el-col>
            <el-date-picker v-model="batch.date" type="date"
                            placeholder="选择日期" style="width: 100%;"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            autocomplete="off">

            </el-date-picker>
          </el-col>

        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUpload = false">取 消</el-button>
        <el-button type="primary" @click="submitBatch('batch')">确 定</el-button>
      </div>
    </el-dialog>
    <div class="root">
      <el-dialog title="导入批次信息" :visible.sync="dialogUpload" width="30%">
        <el-upload
            ref="upload"
            action="http://tzzy.hnjsrcw.com/springboot-hntzzy/person/addBatchInfo.do"
            :file-list="fileList"
            :headers="headers"
            :auto-upload="false"
            :on-error="uploadError"
            :on-success="uploadSuccess"
            :data="data"
        >
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xls文件
            <el-link icon="el-icon-download" type="primary" href="template/train.xls" download="培训模板.xls">点击下载模板</el-link>
          </div>
          <div slot="tip" class="el-upload__tip">请认真核对好上传人员名单，重复上传会覆盖上次导入的人员数据，生成证号之后将不可上传</div>
          <div slot="tip" class="el-upload__tip" v-model="errorMsg">{{ errorMsg }}</div>

        </el-upload>
      </el-dialog>
      <el-select v-show="isSuperAdmin" v-model="city" clearable placeholder="请选择"
                 style="width: 100%" @change="selectBatchByCity">
        <el-option

            v-for="item in citys"
            :key="item.name"
            :label="item.name"
            :value="item.value">
        </el-option>
      </el-select>
      <el-table
          stripe
          @select="selectRow"
          class="batchTable"
          size="medium"
          ref="multipleTable"
          :data="batchTable"
          tooltip-effect="dark"

      >
        <el-table-column v-if="isSuperAdmin"
                         type="selection"
        >
        </el-table-column>
        <el-table-column
            prop="id"
            label="ID"
            min-width="4"
        >
        </el-table-column>
        <el-table-column
            prop="batchName"
            label="批次名称"
            min-width="11%"
        >
        </el-table-column>
        <el-table-column
            prop="cerateDate"
            label="批次创建日期"
            min-width="6%"
        >
        </el-table-column>

        <el-table-column
            prop="founder"
            label="创建人"
            min-width="3%"

        >
        </el-table-column>
        <el-table-column
            prop="publishDate"
            label="证书发布日期"
            min-width="5.0%"
        >
        </el-table-column>
        <el-table-column
            prop="state"
            label="状态"
            min-width="4%"
        >
        </el-table-column>

        <el-table-column
            prop="total"
            label="合计"
            min-width="2%"
        >
        </el-table-column>
        <el-table-column
            prop="statistics"
            label="信息"
            min-width="2%"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="statistics(scope.row.id)">{{ scope.row.statistics }}</el-link>
          </template>

        </el-table-column>
        <el-table-column
            prop="operate"
            label="操作"
            min-width="2%"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="importOrView(scope.row.id, scope.row.operate, scope.row.state)">
              {{ scope.row.operate }}
            </el-link>
          </template>

        </el-table-column>
        <el-table-column
            v-if="!isSuperAdmin"
            prop="trash"
            label="废弃"
            min-width="2%"

        >
          <template slot-scope="scope">
            <el-link type="primary" @click="discard(scope.row)">{{ scope.row.trash || "废弃" }}</el-link>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          class="pagina"
          background
          layout="prev, pager, next"
          @current-change="getList"
          :current-page.sync="trainCurrPage"
          :total="total">
      </el-pagination>
      <hr style="margin-top: 80px"/>
      <el-button-group  v-show="isSuperAdmin" class="shenhe">
        <el-button type="primary" @click="reviewBatch">审核</el-button>
        <el-button type="warning" @click="fail">不通过</el-button>
      </el-button-group>

    </div>
  </div>
</template>

<script>
import request from "@/util/request";

export default {
  name: "Index",
  data() {
    let validateBatchName = (rule, value, callback) => {

      if (this.batch.name === '') {
        callback(new Error("批次名称不能为空"));
      } else {
        let message = this.$message;
        request({
          url: "/batch/checkBatchName.do",
          method: "GET",
          params: {
            batchName: this.batch.name,
            username: this.$store.getters.currUsername()
          }
        }, res => {
          if (res.code === 200) {
            callback();
          } else {
            message.warning(res.msg)
            callback(new Error(res.msg))
          }
        }, err => {
          console.log(err)
        }, this.$store.getters.token());

      }
    };

    let validateBatchDate = (rule, value, callback) => {
      if (this.batch.date === "")
        callback(new Error("日期必须设置"));
      else
        callback();
    };
    return {
      pageSize: 10,
      total: 0,
      city: "*",
      review: [],
      dialogFormVisible: false,
      dialogUpload: false,
      batch: {
        name: '',
        date: ''
      },
      batchRules: {
        batchName: [{validator: validateBatchName, trigger: 'blur'}],
        batchDate: [{validator: validateBatchDate, trigger: 'blur'}]
      },
      batchTable: [],
      fileList: [],
      uploadBatchId: 0,
      errorMsg: '',
      pickerOptions: {

        disabledDate(time) {
          return time.getTime() >  Date.parse("2023-12-31")
        },
      },

    }

  }, methods: {
    submitBatch(batch) {

      let message = this.$message;
      this.$refs[batch].validate((valid) => {
        if (valid) {
          request({
            url: "/batch/createBatch.do",
            method: "GET",
            params: {
              batchName: encodeURI(this.batch.name),
              publishDate: this.batch.date,
              founder: this.$store.getters.currUsername()
            }
          }, res => {
            if (res.code === 200) {
              this.$message({
                message: "创建批次成功",
                type: "success",
                duration: 1000
              })
              this.getList(this.trainCurrPage);
            } else {
              message.warning("创建批次失败")
            }
          }, err => {
            message.warning("创建批次失败")
          }, this.$store.getters.token())
          this.dialogFormVisible = false;
        } else {

          return false;
        }
      })
    },
    getList(val) {
      this.$store.commit("setTrainCurrPage", val);
      let url = ''
      if (this.city !== "*" && this.isSuperAdmin) {
        url = '/batch/getBatchByCity.do'
      } else if (!this.isSuperAdmin) {
        url = "/batch/getBatchOfCurrUser.do"
      } else
        url = '/batch/getBatchPage.do';
      request({
        url: url,
        params: {
          pageNum: this.$store.getters.trainCurrPage,
          pageSize: this.pageSize,
          city: this.city,
          username: this.$store.getters.currUsername()
        },
        method: "GET",
      }, (res) => {
        this.batchTable = res.data.list
        this.total = res.data.total;
      }, (err) => {
        console.log(err)
      }, this.$store.getters.token())
    },
    selectRow(val) {
      this.review = val;
    },
    reviewBatch() {
      if (this.review.length === 0) {
        this.$message.warning("请选择审核的批次")
        return;
      }

      if (this.review.length > 1){
        this.$alert("暂未支持批量审核,请取消多个选择保留一个")
        this.review = []
      }
        request({
          url: "/batch/sh.do",
          method: "GET",
          params: {
            batchId: this.review[0].id,
          }
        }, res => {
          this.$alert(res.msg)
        }, err => {

        }, this.$store.getters.token())

      this.review = [];
      setTimeout(() => {
        this.getList(this.$store.getters.trainCurrPage);
      }, 1000);


    },
    fail(){
      if (this.review.length === 0){
        this.$alert("请选择不通过的批次");
      }else if (this.review.length > 1){
        this.$alert("暂未支持批量操作，请取消多个选择保留一个")
      }else{
        request({
          url: "/batch/failBatch.do",
          method: "GEt",
          params:{
            batchId: this.review[0].id
          }
        }, res=>{
          this.$alert(res.msg);
        }, err=>{}, this.$store.getters.token());
      }
        this.getList(this.$store.getters.trainCurrPage);
    },

    selectBatchByCity(val) {
      let url = "/batch/getBatchByCity.do";
      if (val === "*")
        url = '/batch/getBatchPage.do';
      request({
        url: url,
        method: "GET",
        params: {
          city: val,
          pageNum: this.$store.getters.trainCurrPage,
          pageSize: this.pageSize,
          username: this.$store.getters.currUsername()
        }
      }, res => {
        if (res.code === 200) {
          this.batchTable = res.data.list
          this.total = res.data.total
          this.$message({
            message: "查找成功",
            type: "success",
            duration: 1000
          })
        } else {
          this.$message.error("查找失败, 请重新尝试")
        }
      }, err => {
        this.$message.warning("请重新尝试")
      }, this.$store.getters.token())
    },

    importOrView(id, type, certState) {

      if (type === '====')
        return;
      if (type === "查看") {
        this.$emit("addTabs", "查看", "/personList");
        this.$store.dispatch("setPersonTable", [certState, id])
      } else {
        this.uploadBatchId = id;
        this.dialogUpload = true;
      }
    },
    statistics(id) {
      request({
        url: "/batch/getBatchStatisticsById.do",
        method: "GET",
        params: {
          batchId: id
        }
      }, (result) => {
        result = result.data;
        Object.keys(result).sort();
        let key = [];
        let works = []
        for (let i in result) {
          key.push(i);
        }
        key.sort(function (a, b) {
          return result[a] - result[b];
        });
        for (let k of key) {
          works.push({
            workType: k,
            num: result[k]
          })
          let html = ""
          for (let x of key) {
            html += "<tr><td>" + x + "</td> <td>" + result[x] + "</td></tr>"
          }
          this.$alert('<table id="table_customize">' +
              '    <thead>' +
              '        <tr>' +
              '            <th>名称</th>' +
              '            <th>内容</th>' +
              '        </tr>' +
              '    </thead> <tdody>' + html + '</tbody></table>', {
            dangerouslyUseHTMLString: true
          }).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1000
            })
          }).catch(() => {
            this.$message({
              message: "关闭成功",
              type: "success",
              duration: 1000
            })
          });

        }
      }, (err) => {
        console.log(err)
      }, this.$store.getters.token())
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    uploadError(err, file, fileList) {
      this.errorMsg = err.msg;
    },
    uploadSuccess(response, file, fileList) {
      this.$message.success(response.msg);
      this.getList(this.$store.getters.trainCurrPage);
    },
    discard(row) {
      if (row.trash === '====')
        return;
      request({
        url: "/batch/discard.do",
        method: "GET",
        params: {
          batchId: row.id
        }
      }, res => {
        if (res.code === 200) {
          row.operate = "===";
          row.trash = "==="
          this.$message.success(res.msg)
        } else
          this.$message.warning(res.msg)
      }, err => {
      }, this.$store.getters.token());
    }
  }, computed: {
    citys: function () {
      return this.$store.state.citys;
    },
    isSuperAdmin() {
      return this.$store.getters.admin();

    },
    headers() {
      let store = this.$store;
      return {
        authentication: store.getters.token()
      }
    },
    data() {
      return {
        batchId: this.uploadBatchId
      }
    },
    trainCurrPage: {
      get() {
        return this.$store.getters.trainCurrPage;
      },
      set(currPage) {
        this.$store.commit("setTrainCurrPage", currPage)
      }

    }
  },
  beforeMount() {
    let url = "/batch/getBatchOfCurrUser.do";
    let message = this.$message;

    if (this.$store.getters.admin()) {
      url = "/batch/getBatchPage.do"
    }
    setTimeout(() => {
      request({
        url: url,
        params: {
          pageNum: this.$store.getters.trainCurrPage,
          pageSize: this.pageSize,
          username: this.$store.getters.currUsername()
        },
        method: "GET",
      }, (res) => {
        if (res.code === 200) {

          this.batchTable = res.data.list
          this.total = res.data.total;
        } else
          message.warning(res.msg);
      }, (err) => {
        message.warning(err.msg);
      }, this.$store.getters.token())
    }, 500)


  },
  beforeCreate() {
    setTimeout(() => {
      this.$store.state.loading = false;
    }, 500)

  }

}
</script>

<style>

.root {
  margin-top: 30px;
  text-align: center
}

.batchTable {
  width: 100%;
  font-size: 12px;
  margin-top: 40px
}

.pagina {
  margin-top: 30px;
  float: right
}

.shenhe {
  margin-top: 10px
}

#table_customize {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}

#table_customize td, #table_customize th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 50px;
}

#table_customize thead th {
  background-color: #CCE8EB;
  width: 150px;
}

#table_customize tr:nth-child(odd) {
  background: #fff;
}

#table_customize tr:nth-child(even) {
  background: #F5FAFA;
}

#select-city {
  float: right;
}
</style>